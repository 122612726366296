<script>
    import { api } from 'main/stores.js';
    import EditFormLayout from 'layouts/EditFormLayout.svelte';
    import CamerasForm, { checkValid } from 'components/forms/Cameras.svelte';
</script>

<EditFormLayout 
    title="Создание камеры"
    component={CamerasForm}
    endpoint={$api.cameras}
    redirect="/cameras"
    {checkValid}
/>