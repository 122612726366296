<script>
    import { api } from 'main/stores.js';
    import EditFormLayout from 'layouts/EditFormLayout.svelte';
    import UsersForm, { checkValid } from 'components/forms/User.svelte';
</script>

<EditFormLayout 
    title="Создание пользователя"
    component={UsersForm}
    endpoint={$api.users}
    redirect="/users"
    {checkValid}
/>