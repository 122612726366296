<script>
  import { api } from 'main/stores.js'
  import EditFormLayout from 'layouts/EditFormLayout.svelte'
  import UserForm, { checkValid } from 'components/forms/User.svelte'

  export let data
</script>

<EditFormLayout
  title={`Редактирование пользователя ${data.name}`}
  component={UserForm}
  endpoint={$api.users(data.id)}
  redirect="\users"
  {checkValid}
  {data}
/>
