<script>
  import { onMount } from 'svelte'
  import { token, user, router } from 'main/stores'
  import Navigo from 'navigo'

  import Router from 'main/Router.svelte'
  import Api from 'main/Api.svelte'

  import Navigation from 'components/Navigation.svelte'

  let root
  let component
  let path
  let data

  $router = new Navigo(root)

  onMount(() => {
    $token = localStorage.getItem('token')
    $user = JSON.parse(localStorage.getItem('user'))

    if (!$token) {
      $router.navigate('/auth', true)
    }
  })

  $: console.log({ component, path })
</script>

<Api />
<Router bind:component bind:data bind:path />

{#if path != '/auth'}
  <Navigation />
{/if}

<svelte:component this={component} {data} />
