<script>
    import { router, api } from 'main/stores.js';
    import Table from 'UI/Table.svelte';
    import { msgSuccess } from 'main/modules/Messages.svelte';

    let data = [];
    let actions = [{
        key: 'edit',
        icon: 'pencil'
    }];

    const refresh = () => {
        $api.users.get().then(answer => {
            if(answer){
                data = answer.data.items;
            }
        });
    }

    const remove = item => () => {
        if (confirm(`Удалить пользователя "${item.last_name} ${item.name}"`)) {
            $api.users(item.id).delete().then(answer => {
                if(answer && answer.code == 200){
                    msgSuccess(`Пользователь ${item.last_name} ${item.name} удален успешно`);
                    refresh();
                }
            });
        }
    }

    const edit = item => () => {
        $router.navigate(`/users/${item.id}/edit`)
    }

    refresh();
</script>

<Table caption="Пользователи">
    <thead slot="thead">
        <tr>
            <th class="uk-table-shrink"></th>
            <th class="uk-table-shrink">ID</th>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Email</th>
            <th class="uk-table-shrink"></th>
        </tr>
    </thead>
    <tbody slot="tbody">
        {#each data.filter(d => !d.archive_at) as item}
            <tr>
                <td>
                    <div class="avatar uk-border-circle uk-background-cover" style="background-image: url({item.photo_url});">
                    </div>
                </td>
                <td>{item.id}</td>
                <td>{item.last_name}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td class="uk-flex">
                    <a on:click={edit(item)} class="uk-icon-button uk-margin-small-right" uk-icon="pencil"></a>
                    <a on:click={remove(item)} class="uk-icon-button" uk-icon="trash" uk-tooltip="Удалить пользователя"></a>
                </td>
            </tr>
        {/each}
    </tbody>
</Table>

<style>
    .avatar {
        height: 50px !important;
        width: 50px !important;
    }
</style>