<script>
  import { user, router, token } from 'main/stores'
  import Tabs from 'UI/Tabs.svelte'

  let items = [
    {
      name: 'Главная',
      key: 'main',
      url: '/',
    },
    {
      name: 'Пользователи',
      key: 'users',
      url: '/users',
    },
    {
      name: 'Новости',
      key: 'news',
      url: '/news',
    },
    {
      name: 'Камеры',
      key: 'cameras',
      url: '/cameras',
    },
    {
      name: 'Тарифы',
      key: 'tariffs',
      url: '/tariffs',
    },
    {
      name: 'Клиенты',
      key: 'clients',
      url: '/clients',
    },
    {
      name: 'SIP',
      key: 'sip',
      url: '/sip',
    },
    {
      name: 'Чат',
      key: 'chat',
      url: '/chat',
    },
    {
      name: 'Уведомления',
      key: 'notifications',
      url: '/notifications',
    },
  ]

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')

    $token = null
    $user = null

    $router.navigate('/auth', true)
  }
</script>

<div class="uk-width uk-margin-small-bottom" uk-grid>
  <div class="uk-width-expand">
    <Tabs tabs={items} />
  </div>
  {#if $user}
    <div class="uk-width-auto">
      {$user.name} <a on:click={logout}>Выйти</a>
    </div>
  {/if}
</div>
