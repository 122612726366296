<script context="module">
    export function checkValid(data) {
        let invalid = {
            image: false,
            name: false,
            description: false,
            content: false,
        }

        if (!data.image || data.image == '') invalid.image = true
        if (!data.name || data.name == '') invalid.name = true
        if (!data.description || data.description == '') invalid.description = true
        if (!data.content || data.content == '') invalid.content = true
    
        return invalid
    }
</script>

<script>
    import Form from 'UI/Form.svelte';
    import Input from 'UI/Input.svelte';
    import Button from 'UI/Button.svelte';
    import Textarea from 'UI/Textarea.svelte';

    export let data = {
        image: '',
        name: '',
        description: '',
        content: '',
    }

    export let invalid = {
        image: false,
        name: false,
        description: false,
        content: false,
    }
</script>

<Form>
    <div class="uk-margin-small-top" uk-grid>
        <div class="uk-width">
            <Input label="Изображение" bind:value={data.image} danger={invalid.image} />
            <img class="uk-width-1-2" src={data.image} />
            <Input label="Наименование" bind:value={data.name} danger={invalid.name} />
            <Input label="Краткое описание" bind:value={data.description} danger={invalid.description} />
            <Textarea label="Содержимое" bind:value={data.content} danger={invalid.content} />
        </div>
    </div>
</Form>