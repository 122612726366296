<script>
  import { createEventDispatcher } from 'svelte'
  import Portal from 'svelte-portal'
  import {
    MultiSelect,
    UnorderedList,
    ListItem,
    Tag,
  } from 'carbon-components-svelte'
  import { api } from 'main/stores.js'

  export let cell
  export let headersMap
  export let row
  export let tariffs = []
  export let modalIsOpen = false
  export let cameras = []

  if (typeof row.cameras_ids === 'undefined' || row.cameras_ids === null) {
    row.cameras_ids = []
  } else if (typeof row.cameras_ids === 'string') {
    row.cameras_ids = JSON.parse(row.cameras_ids)
  }

  const dispatch = createEventDispatcher()
  let id = row.id
  let clients_id = row.clients_id
  let tariffs_id = row.tariffs_id
  let name = row.name
  let blacklist_at = row.blacklist_at
  let archive_at = row.archive_at
  let loading = false
  let tariff = null
  let selectedTariff = tariffs_id
  let clientDetailModal = false
  let showClientDetailModalContent = false
  let userCameras = []

  const toBlacklist = async () => {
    if (loading || !id) return
    loading = true
    const answer = await $api.clients(id).blacklist.post()
    loading = false
    dispatch('toBlacklist')
  }

  const removeBlacklist = async () => {
    if (loading || !id) return
    loading = true
    const answer = await $api.clients(id).blacklist.delete()
    loading = false
    dispatch('removeBlacklist')
  }

  const toArchive = async () => {
    if (loading || !id) return
    loading = true
    const answer = await $api.clients(id).archive.post()
    loading = false
    dispatch('toArchive')
  }

  const removeArchive = async () => {
    if (loading || !id) return
    loading = true
    const answer = await $api.clients(id).archive.delete()
    loading = false
    dispatch('removeArchive')
  }

  const setTariff = async () => {
    if (loading || !id) return
    loading = true
    const answer = await $api.clients(id).tariffs(selectedTariff).post()
    loading = false
    dispatch('setTariff')
  }

  $: tariff = tariffs.find((t) => t.id == tariffs_id)
  $: if (clientDetailModal) {
    dispatch('openClientDetailModal', row)
    setTimeout(() => (showClientDetailModalContent = true), 300)
  }
  $: if (modalIsOpen == false) {
    showClientDetailModalContent = false
    clientDetailModal = false
  }
  $: if (row.cameras_ids)
    userCameras = cameras.filter((c) => row.cameras_ids.includes(c.id))
  $: archive_at = row.archive_at
</script>

{#if showClientDetailModalContent && modalIsOpen}
  <Portal target="#clientDetailModal">
    <MultiSelect
      titleText="Камеры"
      label="Выберите камеры..."
      items={cameras.map((c) => ({ id: c.id, text: c.name }))}
      bind:selectedIds={row.cameras_ids}
    />
    <UnorderedList style="margin-top: 10px; margin-bottom: 50px;">
      {#each userCameras as camera}
        <ListItem>{camera.name}</ListItem>
      {/each}
    </UnorderedList>
  </Portal>
{/if}

{#if cell.key === 'tariff'}
  <div class="uk-flex uk-flex-middle">
    {#if tariff}
      <div class="color-circle" style="background-color: {tariff.color}" />
    {/if}
    <div uk-form-custom="target: > * > span:first-child">
      <select bind:value={selectedTariff} on:change={setTariff}>
        <option value={0}>Нет тарифа</option>
        {#each tariffs as tariff}
          <option value={tariff.id}>{tariff.name}</option>
        {/each}
      </select>
      <span class="uk-flex uk-flex-middle">
        <span />
        <span uk-icon="chevron-down" />
      </span>
    </div>
  </div>
{:else if cell.key === 'actions'}
  {#if loading}
    <div uk-spinner />
  {:else}
    {#if !archive_at}
      <a
        on:click={toArchive}
        class="uk-margin-small-right"
        uk-tooltip="Заблокировать"
      >
        Заблокировать
      </a>
    {:else}
      <a
        on:click={removeArchive}
        class="uk-margin-small-right"
        uk-tooltip="Убрать из черного списка"
      >
        Восстановить
      </a>
    {/if}
    {#if !blacklist_at}
      <a
        on:click={toBlacklist}
        class="uk-icon-button uk-margin-small-right"
        uk-tooltip="Добавить в черный список"
      >
        <span uk-icon="ban" />
      </a>
    {:else}
      <a
        on:click={removeBlacklist}
        class="uk-icon-button uk-margin-small-right"
        uk-tooltip="Убрать из черного списка"
      >
        <span uk-icon="history" />
      </a>
    {/if}
  {/if}
  <a
    on:click={() => (clientDetailModal = true)}
    class="uk-icon-button uk-margin-small-right"
    uk-tooltip="Просмотр"
  >
    <span uk-icon="pencil" />
  </a>
{:else if headersMap[cell.key].display}
  {headersMap[cell.key].display(cell.value)}
{:else}
  {cell.value}
{/if}

<style>
  .color-circle {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    margin-right: 7px;
    cursor: pointer;
  }
</style>
