<script context="module">
    export function checkValid(data) {
        let invalid = {
            name: false,
            description: false,
            color: false,
        }

        if (!data.name || data.name == '') invalid.name = true
        if (!data.description || data.description == '') invalid.description = true
        if (!data.color || data.color == '') invalid.color = true
    
        return invalid
    }
</script>

<script>
    import Form from 'UI/Form.svelte';
    import Input from 'UI/Input.svelte';
    import Button from 'UI/Button.svelte';
    import Textarea from 'UI/Textarea.svelte';

    export let data = {
        name: '',
        description: '',
        color: '',
        ext_id: null,
    }

    export let invalid = {
        name: false,
        description: false,
        color: false,
    }
</script>

<Form>
    <div class="uk-margin-small-top" uk-grid>
        <div class="uk-width">
            <Input label="Наименование" bind:value={data.name} danger={invalid.name} />
            <Input label="Краткое описание" bind:value={data.description} danger={invalid.description} />
            <Input label="Внешний идентификатор" bind:value={data.ext_id} />
            <div class="uk-flex uk-flex-middle">
                <div class="color-circle" style="background-color: {data.color}"></div>
                <Input label="Код цвета тарифа" bind:value={data.color} danger={invalid.color} />
            </div>
        </div>
    </div>
</Form>

<style>
    .color-circle {
        width: 7px;
        height: 7px; 
        border-radius: 100%;
        margin-right: 7px;
        cursor: pointer;
    }
</style>