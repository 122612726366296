<script>
    export let type = 'button';
    export let color = 'primary';
    export let size = 'default';
</script>

<button 
    class="uk-button uk-button-{color}" 
    class:uk-button-small="{size == 'small'}"
    class:uk-button-large="{size == 'large'}"
    type="{type}" 
    on:click>

    <slot></slot>

</button>