<script context="module">
    let pos = 'bottom-center';
    let timeout = 5000;

    export const msgSuccess = message => {
        let status = 'success';

        UIkit.notification({ message, status, pos, timeout });
    }

    export const msgDanger = message => {
        let status = 'danger';

        UIkit.notification({ message, status, pos, timeout });
    }
</script>