<script>
    import { api } from 'main/stores.js';
    import EditFormLayout from 'layouts/EditFormLayout.svelte';
    import NewsForm, { checkValid } from 'components/forms/News.svelte';
</script>

<EditFormLayout 
    title="Создание новости"
    component={NewsForm}
    endpoint={$api.news}
    redirect="/news"
    {checkValid}
/>