<script>
    import { api } from 'main/stores.js';
    import EditFormLayout from 'layouts/EditFormLayout.svelte';
    import TariffsForm, { checkValid } from 'components/forms/Tariffs.svelte';

    export let data;
</script>

<EditFormLayout 
    title="Редактирование тарифа {data.name}"
    component={TariffsForm}
    endpoint={$api.tariffs(data.id)}
    redirect="\tariffs"
    {checkValid}
    {data}
/>