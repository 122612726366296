<script>
  import { api } from 'main/stores.js'
  import ViewTableLayout from 'layouts/ViewTableLayout.svelte'
  import ClientsTable from 'components/tables/ClientsTable.svelte'

  let actions = []
</script>

<ViewTableLayout
  title="Клиента"
  description="Организация клиентов в системе."
  component={ClientsTable}
  endpoint={$api.clients}
  path="/clients"
  {actions}
/>
