<script>
    export let width = 'medium';
</script>

<div 
    class="uk-card uk-card-default"
    class:uk-width-small="{width == 'small'}"
    class:uk-width-medium="{width == 'medium'}"
    class:uk-width-large="{width == 'large'}">
    
    <div class="uk-card-header">
        <slot name="header">

        </slot>
    </div>
    

    <div class="uk-card-body">
        <slot name="body">
        
        </slot>
    </div>
    
    <div class="uk-card-footer">
        <slot name="footer">
        
        </slot>
    </div>
</div>