<script>
    import { api, router } from 'main/stores.js';
    import { msgSuccess } from 'main/modules/Messages.svelte';

    import { errorMsg } from 'UI/Messages.svelte'
    import Card from 'UI/Card.svelte';

    export let title;
    export let data;
    export let component;
    export let endpoint;
    export let redirect;
    export let checkValid;

    let invalid;

    const edit = event => {
        if (checkValid) {
            invalid = checkValid(data)

            if (Object.values(invalid).some(val => val)) {
                errorMsg("Форма заполнена некорректно")
                return
            }
        }

        endpoint.post(data).then(answer => {
            console.log({ answer });
            if (answer.code == 200) {
                msgSuccess(`Успешно!`);
                $router.navigate(redirect);
            }
        }).catch(err => {
            if(err.response){
                answer = JSON.parse(err.response);
                
                if (answer.code == 400) {
                    errorMsg("Форма заполнена некорректно")
                }
            }
        });
    }
</script>


<div class="uk-flex uk-flex-middle uk-flex-center uk-width">
    <Card width="large">
        <h3 class="uk-card-title uk-margin-remove-bottom" slot="header">{title}</h3>

        <div class="uk-form-stacked" slot="body">
            <svelte:component this={component} bind:data {invalid} />
        </div>

        <div class="uk-width uk-text-right" slot="footer">
            <a class="uk-button uk-button-text" on:click={edit}>Сохранить</a>
        </div>
    </Card>
</div>