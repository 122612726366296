<script context="module">
    let pos = 'bottom-right';

    export const successMsg = (message, timeout = 5000) => {
        UIkit.notification({ message, status: 'success', pos, timeout });
    }

    export const warningMsg = (message, timeout = 5000) => {
        UIkit.notification({ message, status: 'warning', pos, timeout });
    }

    export const errorMsg = (message, timeout = 5000) => {
        UIkit.notification({ message, status: 'danger', pos, timeout });
    }
</script>