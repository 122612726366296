<script>
  import { api } from 'main/stores'
  import {
    TextInput,
    TextArea,
    Button,
    InlineLoading,
  } from 'carbon-components-svelte'

  let phones = ''
  let title = ''
  let body = ''
  let loading = false

  async function sendInfoNotify() {
    loading = true
    let result
    if (phones != '') {
      result = await $api.messages.sendInfoNotify.get({
        phones,
        title,
        body,
      })
    } else {
      result = await $api.messages.sendInfoNotify.get({
        title,
        body,
      })
    }
    console.log({ result })
    loading = false
  }
</script>

<div style="padding:40px;">
  <TextInput
    bind:value={phones}
    labelText="Номера телефонов (через запятую)"
    placeholder="Введите если хотите отправить конкретным клиентам..."
  />
  <TextInput
    bind:value={title}
    labelText="Заголовок уведомления"
    placeholder="Введите заголовок..."
    required
  />
  <TextArea
    bind:value={body}
    labelText="Контент уведомления"
    placeholder="Введите контент..."
  />
  {#if loading}
    <InlineLoading status="active" description="Отправляем..." />
  {:else}
    <Button on:click={sendInfoNotify}>Отправить</Button>
  {/if}
</div>
