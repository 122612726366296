<script context="module">
    export function checkValid(data) {
        let invalid = {
            name: false,
            address: false,
            link: false,
        }

        if (!data.name || data.name == '') invalid.name = true
        if (!data.address || data.address == '') invalid.address = true
        if (!data.link || data.link == '') invalid.link = true
    
        return invalid
    }
</script>

<script>
    import Form from 'UI/Form.svelte';
    import Input from 'UI/Input.svelte';

    export let data = {
        name: '',
        address: '',
        link: '',
    }

    export let invalid = {
        name: false,
        address: false,
        link: false,
    }
</script>

<Form>
    <div class="uk-margin-small-top" uk-grid>
        <div class="uk-width">
            <Input label="Наименование" bind:value={data.name} danger={invalid.name} />
            <Input label="Адрес" bind:value={data.address} danger={invalid.address} />
            <Input label="Embed ссылка" bind:value={data.link} danger={invalid.link} />
            <img class="uk-width-1-2" src="{data.link + '/preview.jpg'}" />
        </div>
    </div>
</Form>