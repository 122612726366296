<script>
    import { createEventDispatcher } from 'svelte';

    import Button from 'UI/Button.svelte';

    export let size = 'default';
    export let items = [{
        key: 'create',
        name: 'Новый'
    }];

    const dispatch = createEventDispatcher();

    const action = item => () => {
        dispatch(item.key);
    }
</script>

<div class="uk-flex">
    {#each items as item}
        <div class="uk-margin-small-right">
            <Button color="{item.color}" on:click="{action(item)}" {size}>{item.name}</Button>
        </div>
    {/each}
</div>