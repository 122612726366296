<script>
  import { router, api } from 'main/stores'

  import Auth from 'pages/Auth.svelte'

  import Users from 'pages/Users.svelte'
  import UsersCreate from 'pages/UsersCreate.svelte'
  import UsersEdit from 'pages/UsersEdit.svelte'

  import News from 'pages/News.svelte'
  import NewsCreate from 'pages/NewsCreate.svelte'
  import NewsEdit from 'pages/NewsEdit.svelte'

  import Cameras from 'pages/Cameras.svelte'
  import CamerasCreate from 'pages/CamerasCreate.svelte'
  import CamerasEdit from 'pages/CamerasEdit.svelte'

  import Tariffs from 'pages/Tariffs.svelte'
  import TariffsCreate from 'pages/TariffsCreate.svelte'
  import TariffsEdit from 'pages/TariffsEdit.svelte'

  import Clients from 'pages/Clients.svelte'

  import Chat from 'pages/Chat.svelte'

  import Sip from './pages/Sip.svelte'
  import SipCreate from 'pages/SipCreate.svelte'
  import SipEdit from 'pages/SipEdit.svelte'

  import NotificationsPage from 'pages/NotificationsPage.svelte'

  export let component
  export let data
  export let path = window.location.pathname

  $router
    .on('/auth', () => {
      component = Auth
    })
    .on('/users', () => {
      component = Users
    })
    .on('/users/create', () => {
      component = UsersCreate
    })
    .on(
      '/users/:id/:action',
      (params) => {
        if (params.action == 'edit') {
          component = UsersEdit
        }
      },
      {
        before: (done, params) => {
          $api
            .users(params.id)
            .get()
            .then((answer) => {
              data = answer.data
              done()
            })
        },
      }
    )
    .on('/news', () => {
      component = News
    })
    .on('/news/create', () => {
      component = NewsCreate
    })
    .on(
      '/news/:id/:action',
      (params) => {
        if (params.action == 'edit') {
          component = NewsEdit
        }
      },
      {
        before: (done, params) => {
          $api
            .news(params.id)
            .get()
            .then((answer) => {
              data = answer.data
              done()
            })
        },
      }
    )
    .on('/cameras', () => {
      component = Cameras
    })
    .on('/cameras/create', () => {
      component = CamerasCreate
    })
    .on(
      '/cameras/:id/:action',
      (params) => {
        if (params.action == 'edit') {
          component = CamerasEdit
        }
      },
      {
        before: (done, params) => {
          $api
            .cameras(params.id)
            .get()
            .then((answer) => {
              data = answer.data
              done()
            })
        },
      }
    )
    .on('/tariffs', () => {
      component = Tariffs
    })
    .on('/tariffs/create', () => {
      component = TariffsCreate
    })
    .on(
      '/tariffs/:id/:action',
      (params) => {
        if (params.action == 'edit') {
          component = TariffsEdit
        }
      },
      {
        before: (done, params) => {
          $api
            .tariffs(params.id)
            .get()
            .then((answer) => {
              data = answer.data
              done()
            })
        },
      }
    )
    .on('/clients', () => {
      component = Clients
    })
    .on('/sip', () => {
      component = Sip
    })
    .on('/sip/create', () => {
      component = SipCreate
    })
    .on('/chat', () => {
      component = Chat
    })
    .on('/notifications', () => {
      component = NotificationsPage
    })
    .notFound(() => {
      component = null
    })
    .resolve()

  $router.hooks({
    after: (params) => {
      path = window.location.pathname
    },
  })
</script>
