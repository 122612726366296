<script>
    export let value = '';
    export let placeholder = '';
    export let label = '';
    export let success = false;
    export let danger = false;
    export let disabled = false;
    export let small = false;
    export let large = false;
</script>

<div class="uk-margin uk-width">
    <label class="uk-form-label">{label}</label>
    <div class="uk-form-controls">
        <input
            class="uk-input" 
            class:uk-form-small={small}
            class:uk-form-large={large}
            class:uk-form-success={success}
            class:uk-form-danger={danger}
            disabled={disabled}
            type="password"
            placeholder="{placeholder}" 
            bind:value
            on:change>
    </div>
</div>