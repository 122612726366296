<script>
    import { router, api } from 'main/stores.js';
    import { msgSuccess } from 'main/modules/Messages.svelte';
    import Table from 'UI/Table.svelte';

    let data = [];

    export const refreshCameras = () => {
        $api.cameras.get().then(answer => {
            if (answer && answer.code == 200) {
                data = answer.data.items;
            }
        });
    }

    const edit = item => () => {
        console.log({item});
        $router.navigate('/cameras/' + item.id + '/edit');
    }

    const remove = item => () => {
        if (confirm(`Удалить камеру "${item.address}"`)) {
            $api.cameras(item.id).delete().then(answer => {
                if(answer && answer.code == 200){
                    msgSuccess(`Камера ${item.address} удалена успешно`);
                    refreshCameras();
                }
            });
        }
    }

    refreshCameras();
</script>

<Table caption="Объекты камер">
    <thead slot="thead">
        <tr>
            <th class="uk-table-shrink">ID</th>
            <th class="uk-table-shrink">Превью</th>
            <th class="uk-table-expand">Адрес</th>
            <th class="uk-table-small">Embed ссылка</th>
            <th class="uk-table-shrink"></th>
        </tr>
    </thead>
    <tbody slot="tbody">
        {#each data as item}
            <tr>
                <td>{item.id}</td>
                <td>
                    <img height="50" src="{item.link + '/preview.jpg'}" />
                </td>
                <td>{item.address}</td>
                <td>{item.link}</td>
                <td class="uk-flex">
                    <a on:click={edit(item)} class="uk-icon-button uk-margin-small-right" uk-icon="pencil"></a>
                    <a on:click={remove(item)} class="uk-icon-button" uk-icon="trash"></a>
                </td>
            </tr>
        {/each}
    </tbody>
</Table>