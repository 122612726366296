<script>
  import { onMount } from 'svelte'
  import Launch16 from 'carbon-icons-svelte/lib/Launch16'
  import { router, api } from 'main/stores.js'
  import { msgSuccess } from 'main/modules/Messages.svelte'
  import Table from 'UI/Table.svelte'
  import Row from './ClientsTableRow.svelte'
  import {
    Modal,
    DataTable,
    Pagination,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
    DataTableSkeleton,
  } from 'carbon-components-svelte'
  import {
    searchInStrings,
    searchInString,
  } from '../../../helpers/strings.helper'

  let headers = [
    { key: 'id', value: 'ID' },
    { key: 'clients_id', value: 'Лицевой счет' },
    { key: 'name', value: 'Наименование' },
    { key: 'tariff', value: 'Тариф' },
    {
      key: 'blacklist_at',
      value: 'В черном списке с',
      display: (blacklist_at) =>
        blacklist_at ? moment(blacklist_at).format('DD.MM.YYYY HH:mm:ss') : '',
    },
    { key: 'actions', value: '' },
  ]
  let headersMap = {}
  headers.forEach((h) => (headersMap[h.key] = h))

  let data = []
  let activeClient = {}
  let filteredData = []
  let rows = []
  let cameras = []
  let tariffs = []
  let open = false
  let totalItems = 0
  let pageSizes = [10, 15, 20]
  let page = 1
  let pageSize = 10
  let search = ''
  let loading = true

  export const refresh = async () => {
    loading = true
    const answer = await $api.clients.get({ limit: 999 })
    if (answer && answer.code == 200) {
      data = answer.data.items
    }
    loading = false
  }

  const getTariffs = async () => {
    const answer = await $api.tariffs.get({ limit: 999 })
    if (answer && answer.code == 200) {
      tariffs = answer.data.items
    }
  }

  const fetchCameras = async () => {
    const answer = await $api.cameras.get()
    if (answer && answer.code == 200) {
      cameras = answer.data.items
    }
  }

  const saveActiveClient = async () => {
    if (!activeClient) return
    const answer = await $api.clients(activeClient.id).post({
      cameras_ids: activeClient.cameras_ids,
    })
    if (answer && answer.code == 200) {
      msgSuccess('Успешно сохранено')
    }
  }

  onMount(async () => {
    await getTariffs()
    await refresh()
    await fetchCameras()
    loading = false
  })

  $: rows = filteredData.filter(
    (_, i) => i >= pageSize * (page - 1) && i < pageSize * page
  )
  $: totalItems = filteredData.length
  $: if (search && search != '') {
    filteredData = data.filter((item) =>
      searchInStrings([item.name, item.clients_id], search)
    )
  } else {
    filteredData = data
  }
</script>

<Modal
  modalHeading="Редактирование клиента {activeClient.name}"
  primaryButtonText="Сохранить"
  secondaryButtonText="Отмена"
  on:click:button--secondary={() => (open = false)}
  on:submit={saveActiveClient}
  bind:open
>
  <div id="clientDetailModal" />
</Modal>

{#if loading}
  <DataTableSkeleton showHeader={false} />
{:else}
  <DataTable {headers} {rows}>
    <Toolbar>
      <ToolbarContent>
        <ToolbarSearch bind:value={search} />
      </ToolbarContent>
    </Toolbar>
    <span slot="cell" let:row let:cell>
      <Row
        on:toBlacklist={refresh}
        on:removeBlacklist={refresh}
        on:setTariff={refresh}
        on:toArchive={refresh}
        on:removeArchive={refresh}
        on:openClientDetailModal={(e) => {
          open = true
          activeClient = e.detail
        }}
        modalIsOpen={open}
        {tariffs}
        {row}
        {cell}
        {cameras}
        {headersMap}
      />
    </span>
  </DataTable>
  <Pagination {totalItems} {pageSizes} bind:page bind:pageSize />
{/if}
