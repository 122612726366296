<script>
    import { api } from 'main/stores.js';
    import ViewTableLayout from 'layouts/ViewTableLayout.svelte';
    import UsersTable from 'components/tables/UsersTable.svelte';

    let actions = [{
        key: 'create',
        name: 'Создать пользователя'
    }]
</script>

<ViewTableLayout 
    title="Пользователи"
    description="Пользователи системы, которые могут пользоваться всем фукнционалом."
    component={UsersTable}
    endpoint={$api.users}
    path="/users"
    {actions}
/>