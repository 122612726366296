<script>
    import { router } from 'main/stores.js';

    export let tabs = [{
        key: 'item',
        name: 'Item'
    }];
    export let active = tabs[0];

    const setActive = tab => {
        active = tab;
    }

    const goTo = tab => () => {
        if(tab.url) $router.navigate(tab.url);
    }

    tabs.map(tab => {
        if(tab.url) {
            if(window.location.pathname.search(tab.url) !== -1) {
                active = tab;
            }
        }
    });
</script>

<ul uk-tab>
    {#each tabs as tab (tab.key)}
        <li class:uk-active="{active == tab}">
            <a on:click={goTo(tab)} style="font-size: 18px; font-weight: 400; color: black;">{tab.name}</a>
        </li>
    {/each}
</ul>