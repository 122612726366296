<script>
    export let columns = [{
        key: 'id',
        name: 'Идентификатор'
    }, {
        key: 'name',
        name: 'Наименование'
    }];
    export let data = [];
    export let caption = '';
</script>

<div class="uk-card uk-card-default uk-card-small uk-card-body uk-padding-remove uk-margin-remove">
    <table class="uk-table uk-table-divider">
        <slot name="thead">
            <thead>
                <tr>
                    {#each columns as column}
                        <th>{column.name}</th>
                    {/each}
                </tr>
            </thead>
        </slot>
        <slot name="tbody">
            <tbody>
                {#each data as item}
                    <tr>
                        {#each columns as column}
                            <td>{item[column.key]}</td>
                        {/each}
                    </tr>
                {/each}
            </tbody>
        </slot>
    </table>
</div>