<script>
  import { api, token, router, user } from 'main/stores.js'
  import Card from 'UI/Card.svelte'
  import { errorMsg } from 'UI/Messages.svelte'

  export let data = {
    email: '',
    password: '',
  }

  let promise

  const login = () => {
    let base64 = btoa(data.email + ':' + data.password)

    $api.off('request')
    $api.on('request', function (xhr) {
      xhr.setRequestHeader('Authorization', 'Basic ' + base64)
    })

    promise = $api.users.auth
      .post(data)
      .then((answer) => {
        if (answer.code == 200) {
          $token = answer.data.token
          $user = answer.data.user
          $router.navigate('/')
        }
      })
      .catch((answer) => {
        if (answer.response) {
          answer = JSON.parse(answer.response)

          if (answer.code == 404) {
            errorMsg('Пользователь не найден')
          }
        }
      })
  }
</script>

<Card>
  <h3 class="uk-card-title uk-margin-remove-bottom" slot="header">
    Авторизация
  </h3>

  <div class="uk-form-stacked" slot="body">
    <div class="uk-margin">
      <div class="uk-form-controls">
        <input
          class="uk-input"
          bind:value={data.email}
          type="email"
          placeholder="email"
        />
      </div>
    </div>
    <div class="uk-margin">
      <div class="uk-form-controls">
        <input
          class="uk-input"
          bind:value={data.password}
          type="password"
          placeholder="пароль"
        />
      </div>
    </div>
  </div>

  <div class="uk-width uk-text-right" slot="footer">
    {#await promise}
      <span uk-spinner />
    {:then data}
      <a class="uk-button uk-button-text" on:click={login}>Войти</a>
    {:catch error}
      <a class="uk-button uk-button-text" on:click={login}>Войти</a>
    {/await}
  </div>
</Card>
