<script>
  import { api, token, user, router } from 'main/stores.js'
  import RestClient from 'another-rest-client'
  import config from 'config'

  $api = new RestClient(config.API_URL)

  token.subscribe((value) => {
    if (value) {
      localStorage.setItem('token', value)

      $api.off('request')
      $api.on('request', function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + value)
      })
    }
  })

  user.subscribe((value) => {
    if (value) {
      localStorage.setItem('user', JSON.stringify(value))
    }
  })

  $api.res({
    users: ['auth'],
    news: 0,
    cameras: 0,
    tariffs: 0,
    sip: 0,
    messages: {
      clients: ['answered'],
      update: 0,
      sendInfoNotify: 0,
    },
    clients: ['blacklist', 'tariffs', 'archive'],
  })

  $api.off('response')
  $api.on('response', function (xhr) {
    if (xhr.status == 401 || xhr.status == 403) {
      $router.navigate('/auth')
    }
  })
</script>
