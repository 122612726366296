<script>
    import { api } from 'main/stores.js';
    import EditFormLayout from 'layouts/EditFormLayout.svelte';
    import CamerasForm, { checkValid } from 'components/forms/Cameras.svelte';

    export let data;
</script>

<EditFormLayout 
    title="Редактирование объекта камеры {data.name}"
    component={CamerasForm}
    endpoint={$api.cameras(data.id)}
    redirect="\cameras"
    {data}
    {checkValid}
/>