export const searchInString = (string, search) =>
  string.toString().toLowerCase().search(search.toString().toLowerCase()) !== -1

export const searchInStrings = (strings, search) =>
  strings.some(
    (string) =>
      string
        .toString()
        .toLowerCase()
        .search(search.toString().toLowerCase()) !== -1
  )
