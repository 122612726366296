<script>
  import { Button, TextInput, Link } from 'carbon-components-svelte'

  export let data = {}

  let homeRtspKey = 1

  const addRtspHomeItem = () =>
    (data.rtsp_home = [...data.rtsp_home, { key: homeRtspKey++, url: '' }])
  const removeRtspHomeItem = (homeRtspItem) =>
    (data.rtsp_home = data.rtsp_home.filter(
      (item) => item.key != homeRtspItem.key
    ))

  $: if (data) {
    console.log({ data })
    if (!data.sip_login) data.sip_login = ''
    if (!data.apartment) data.apartment = ''
    if (!data.rtsp_target) data.rtsp_target = ''
    if (!data.rtsp_home) {
      data.rtsp_home = []
    } else if (!Array.isArray(data.rtsp_home)) {
      data.rtsp_home = data.rtsp_home.split(',').map((item) => ({
        key: homeRtspKey++,
        url: item,
      }))
    }
  }
</script>

<TextInput
  bind:value={data.sip_login}
  labelText="SIP логин"
  placeholder="Введите SIP логин..."
/>
<TextInput
  bind:value={data.apartment}
  labelText="Номер квартиры"
  placeholder="Введите номер квартиры..."
/>
<TextInput
  bind:value={data.rtsp_target}
  labelText="Основной RTSP поток"
  placeholder="Введите основной rtsp поток..."
/>
<div style="margin-top: 20px">
  <Button on:click={addRtspHomeItem}>Добавить RTSP поток дома</Button>
  <ul style="margin-top: 20px">
    {#each data.rtsp_home as homeRtspItem, i}
      <li>
        <input style="width: 80%" bind:value={homeRtspItem.url} />
        <Link on:click={removeRtspHomeItem}>Удалить</Link>
      </li>
    {/each}
  </ul>
</div>
