<script>
  import { onMount, onDestroy } from 'svelte'
  import { api, user } from 'main/stores'
  import config from 'config'
  import { DatePicker, DatePickerInput } from 'carbon-components-svelte'
  import ru from 'flatpickr/dist/l10n/ru.js'

  import Textarea from 'UI/Textarea.svelte'
  import Button from 'UI/Button.svelte'
  import Container from 'UI/Container.svelte'
  import List from 'UI/List.svelte'
  import Spinner from 'UI/Spinner.svelte'
  import { successMsg, errorMsg } from 'UI/Messages.svelte'

  moment.locale('ru')

  const AES_KEY = CryptoJS.enc.Base64.parse(
    'SmFDdEZyVFR4ZUMzZ1NDQWJlUXlSYXhnTm9wcmJzRFI='
  ) //JaCtFrTTxeC3gSCAbeQyRaxgNoprbsDR
  const AES_IV = CryptoJS.enc.Base64.parse('OTg3MTg1YzQ0MzY3NjRiNg==') //987185c4436764b6

  try {
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: 'b49c001e-07e9-44af-96d7-5cec8bccf6ef',
    })

    beamsClient
      .start()
      .then(() => beamsClient.addDeviceInterest('orbita_chat_admin'))
      .catch(console.error)
  } catch (er) {
    console.error(er)
  }

  // const crypt = new JSEncrypt();
  // crypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCIs3M8mgnPVyt1UcGOz30/ZXgZhDfhX7r+9AB155vynmbey+ISTOZQ4uAz5McSgyP4/H4SnSQNSix0G1I2qskZufJXUBAGm9jjF0zlHxM9xHJoZsj2w9hISPhBcq9yuPdhwvSJz6KAwrjlWnFUGjPo1UvvVyqxoMAHGK3B9jrZuwIDAQAB');
  // crypt.setPrivateKey('MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAIizczyaCc9XK3VRwY7PfT9leBmEN+Ffuv70AHXnm/KeZt7L4hJM5lDi4DPkxxKDI/j8fhKdJA1KLHQbUjaqyRm58ldQEAab2OMXTOUfEz3EcmhmyPbD2EhI+EFyr3K492HC9InPooDCuOVacVQaM+jVS+9XKrGgwAcYrcH2Otm7AgMBAAECgYB82vo7ta2UFNf6laumFlkdGD5zf9HSJS+UQaRSDDLhjFAE2k8ny9jos+93NlOk0uZ+OY+kzKXrBX5IpdOXXrHBWDYZ1NicSYPZ7KYLbTNnb41xLTBVGh/k+hGmq7sTO7ea5DJUYxHzZvGw8D4fAzP0JgUK1CtULxg4461ioA0BYQJBAM5jjr1pnQtqsOBcZ0b0sR5VYbdEUrSoUfL+DFmSyPrdNG8uNGCCwr5WAqUvPMLp/Bk41gTQmjyseUCU8d6qBlkCQQCpj4tpJW2Z1rtX6eEcmIDq90asFxo9/P7N1gFpVkjNL+rrCh5Kry8IHpgllEbxy+P8BkyT/cv448hMuUkEMYYzAkB5KlOjebx79l/u5/Ct5HuTLlcWq6LOcr7hpCoF8DnTpUfiOcMAIeRIr+APsALA/3NEL2zeGMVj93Gb65VvvmvJAkATUQEGx3bG8cmB6Gj2wwDcw7LPZApfSXUVqe2bPHMqvOy8PfjZdT+cYy/eCBhU729XhLgwm9ci0YA+DVWeHq41AkEApPaEhjzIoCDS64pDJcKxNoTNRrGppYXDoIvY+4ykz0ZolWl6cvPvVFG3PeONet/Ln4OO3kvlfTV5dmBbo7U4mg==');

  function encryptAES(message = '') {
    let encryptedMessage = ''

    try {
      let code = CryptoJS.AES.encrypt(message, AES_KEY, {
        iv: AES_IV,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      })
      encryptedMessage = code.toString()
    } catch (e) {
      console.error(e)
    }

    return encryptedMessage
  }

  function decryptAES(message = '') {
    let decryptedMessage = ''

    try {
      let code = CryptoJS.AES.decrypt(message, AES_KEY, {
        iv: AES_IV,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      })
      decryptedMessage = code.toString(CryptoJS.enc.Utf8)
    } catch (e) {
      console.error(e)
    }

    return decryptedMessage
  }

  let message = ''
  let clients = []
  let filteredClients = []
  let clients_ids = []
  let messages = []
  let tariffs = []
  let tariffsMap = {}
  let tariffsExtMap = {}
  let messagesEl
  let activeClientTotalPages = 1
  let activeClient
  let activeClientScrollPosition = 0
  let isConnect = false
  let search = ''
  let period = localStorage.getItem('period') ?? '15 days'
  let date_start = null
  let loading = false

  const socket = io(config.CHAT_SOCKET_URL, {
    autoConnect: false,
  })

  socket.on('connect', () => {
    isConnect = true
  })

  socket.on('message', async (msg) => {
    if (activeClient && msg.client == activeClient.clients_id) {
      let message = {
        client: msg.client,
        client_name: msg.client_name,
        from: msg.from,
        before_users_id: messages[messages.length - 1].users_id,
        users_id: msg.users_id,
        manager_name: msg.manager_name,
        manager_photo: msg.manager_photo,
        content: decryptAES(msg.text) || msg.text,
        before_created_at: moment(
          messages[messages.length - 1].created_at
        ).format('YYYY-MM-DD HH:mm'),
        created_at: moment().format('YYYY-MM-DD HH:mm'),
      }

      messages = [...messages, message]

      clients = clients.map((item) => {
        if (msg.client == item.clients_id) item.last_message = message
        return item
      })
    }
  })

  socket.on('notification', async (n) => {
    if (activeClient && n.clients_id == activeClient.clients_id) return
    let findedClient = clients.find((item) => n.clients_id == item.clients_id)
    console.log({ findedClient })
    if (!findedClient) {
      await fetchClients()
    }

    if (!clients.some((item) => item.clients_id == n.clients_id)) {
      clients = [
        ...clients,
        {
          name: n.clients_name || 'Клиент ' + (clients.length + 1),
          clients_id: n.clients_id,
          new: true,
        },
      ]
    } else {
      clients = clients.map((item) => {
        if (n.clients_id == item.clients_id) item.new = true
        return item
      })
    }
  })

  // socket.on('new client', (msg) => {
  //   console.log({ msg }) // 'G5p5...'
  // })

  const onPeriodChange = () => {
    localStorage.setItem('period', period)
    fetchClients()
  }

  const fetchClients = () =>
    new Promise((res, rej) => {
      loading = true

      const params = {
        period,
      }
      if (date_start) params.date_start = date_start

      $api.messages.clients
        .get(params)
        .then((answer) => {
          if (answer.code == 200) {
            clients = answer.data.map((item) => ({
              ...item,
              utm: item.utm ? JSON.parse(item.utm) : null,
            }))
            res(clients)
          }
        })
        .catch(rej)
        .finally(() => {
          loading = false
        })
    })

  const send = () => {
    if (message.trim() == '') return

    let msg = {
      from: socket.id,
      client: activeClient.clients_id,
      client_name: activeClient.name,
      users_id: $user.id,
      manager: $user.id,
      manager_name: $user.name,
      manager_photo: $user.photo_url,
      text: encryptAES(message.trim()),
      room: activeClient.clients_id,
      before_users_id: messages.length
        ? messages[messages.length - 1].users_id
        : null,
      before_created_at: messages.length
        ? moment(messages[messages.length - 1].created_at).format(
            'YYYY-MM-DD HH:mm'
          )
        : null,
    }

    message = ''
    socket.emit('message', msg)

    clients = clients.map((item) => {
      if (activeClient && item.clients_id == activeClient.clients_id)
        item.new = false
      return item
    })
  }

  let toggleAnsweredFlagLoading = false
  const toggleAnsweredFlag = async () => {
    if (!activeClient || toggleAnsweredFlagLoading) return

    toggleAnsweredFlagLoading = true
    const answer = await $api.messages
      .clients(activeClient.clients_id)
      .answered.get({
        value: !activeClient.new,
      })

    if (answer.code == 200) {
      clients = clients.map((c) => {
        if (c.clients_id == activeClient.clients_id) c.new = !activeClient.new
        return c
      })
    }

    toggleAnsweredFlagLoading = false
  }

  let getMessagesLoading = false
  async function fetchMessages(item) {
    if (getMessagesLoading) return

    if (!activeClient || activeClient.clients_id != item.clients_id) {
      activeClientTotalPages = null
      activeClient = item
      activeClient.page = 1
    } else if (item.page < activeClientTotalPages) {
      activeClient.page++
    }

    if (item.page === activeClientTotalPages) return

    getMessagesLoading = true

    const answer = await $api.messages
      .clients(item.clients_id)
      .get({ page: activeClient.page })

    if (answer.code == 200) {
      activeClientTotalPages = answer.data.total_pages
      let newMessages = answer.data.items.reverse()

      let prevItem = null
      newMessages = newMessages.map((item) => {
        let content = item.content
        item.content = decryptAES(content)

        if (item.content == '') {
        } else if (!item.content) {
          item.content = content
        }

        if (prevItem) {
          item.before_created_at = prevItem.created_at
          item.before_users_id = prevItem.users_id
        }

        prevItem = item
        return item
      })

      if (activeClient.page == 1) {
        messages = newMessages
      } else {
        messages = [...newMessages, ...messages]
      }
    }

    getMessagesLoading = false
  }

  const getMessages = (item) => async () => {
    if (!activeClient || item.clients_id != activeClient.clients_id) {
      if (activeClient) socket.emit('leave', activeClient.clients_id)
      socket.emit('join', item.clients_id)
    }

    fetchMessages(item)
  }

  const scrollBottom = () => {
    if (messagesEl) {
      if (activeClient && activeClient.page === 1) {
        setTimeout(() => {
          messagesEl.scrollTop = messagesEl.scrollHeight + 1000
        }, 10)
      } else {
        setTimeout(() => {
          messagesEl.scrollTop =
            messagesEl.scrollHeight - activeClientScrollPosition
        }, 10)
      }
    }
  }

  const scrollMessagesEl = (event) => {
    if (event.target && activeClient) {
      if (event.target.scrollTop == 0) {
        activeClientScrollPosition = event.target.scrollHeight
        fetchMessages(activeClient)
      }
    }
  }

  const editName = (client) => () => {
    var name = prompt(`Изменение имени "${client.name}"`, client.name)

    if (name)
      $api.messages
        .clients(client.clients_id)
        .post({ name })
        .then((answer) => {
          if (answer.code == 200) {
            clients = clients.map((item) => {
              if (item.clients_id == client.clients_id) item.name = name
              return item
            })
          }
        })
  }

  const getTariffs = async () => {
    const answer = await $api.tariffs.get({ limit: 999 })
    if (answer && answer.code == 200) {
      tariffs = answer.data.items
    }
  }

  function hashCode(str) {
    // java String#hashCode
    var hash = 0
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }

  function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase()

    return '00000'.substring(0, 6 - c.length) + c
  }

  onMount(async () => {
    fetchClients()
    await getTariffs()
    socket.open()
  })

  onDestroy(() => {
    socket.close()
  })

  let onSearchChangeTimeout = null
  async function onSearchChange() {
    if (onSearchChangeTimeout) clearTimeout(onSearchChangeTimeout)
    onSearchChangeTimeout = setTimeout(() => {
      filteredClients = clients.filter((client) => {
        if (
          client.name &&
          client.name
            .toString()
            .toLowerCase()
            .trim()
            .search(search.toLowerCase().trim()) != -1
        ) {
          return true
        }

        if (
          client.clients_id &&
          client.clients_id
            .toString()
            .toLowerCase()
            .trim()
            .search(search.toLowerCase().trim()) != -1
        ) {
          return true
        }

        if (activeClient && client.clients_id == activeClient.clients_id) {
          return true
        }

        return false
      })
    }, 300)
  }

  function urlify(text) {
    if (text) {
      let urlRegex = /(https?:\/\/[^\s]+)/g
      let result = text.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>'
      })

      let orbitaResult = ''
      if (result.split('\r\n')[0].includes('orbita-file:')) {
        const url =
          'http://87.245.140.169:1200/files/get?uid=' +
          result.split('\r\n')[0].split('##')[1]
        const filename = result
          .split('\r\n')[0]
          .split('##')[0]
          .split('orbita-file:')[1]

        if (filename.includes('.png') || filename.includes('.jpg')) {
          orbitaResult += `<img src="${url}" width="200" /><br/>`
        }
        if (filename.includes('.mp4')) {
          orbitaResult += `<video controls width="250"><source src="${url}" type="video/mp4"></video><br/>`
        }
        orbitaResult +=
          '<a href="' + url + '" target="_blank">' + filename + '</a>'
        result.split('\r\n').forEach((item, idx) => {
          if (idx) orbitaResult += item
        })
      } else {
        orbitaResult = result
      }

      return orbitaResult
    }
    return text

    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  const getTariffColor = (client) => {
    if (client.tariffs_id && tariffsMap[client.tariffs_id])
      return tariffsMap[client.tariffs_id].color
    if (client.services && client.services.length)
      return client.services[0].color
    return 'rgba(0,0,0,0.15)'
  }

  const handleEditMessage = async (message) => {
    let newMessage = prompt(`Редактирование сообщения`, message.content)
    if (!newMessage) return
    $api
      .messages(message.id)
      .update.post({
        content: encryptAES(newMessage.trim()),
      })
      .then((result) => {
        // console.log({ result })
        successMsg('Сообщение успешно изменено!')
        messages = messages.map((item) => {
          if (item.id === result.data.message.id)
            return {
              ...item,
              content: newMessage,
            }
          return item
        })
      })
      .catch(() => errorMsg('При изменении сообщения произошла ошибка!'))
  }

  const clearFilters = () => {
    console.log('clearFilters')
    date_start = null
    period = '15 days'
  }

  $: console.log({ activeClient })
  $: messages, scrollBottom()
  $: if (search != '' && clients.length) {
    onSearchChange()
  } else if (clients.length) {
    filteredClients = clients
      .filter((c) => !c.blacklist_at)
      .map((c) => ({
        ...c,
        services: c.services
          ? JSON.parse(c.services)
              .map((s) => tariffsExtMap[s.id])
              .filter((t) => t)
          : [],
      }))
      .sort(
        (a, b) =>
          moment(b.last_message.created_at).unix() -
          moment(a.last_message.created_at).unix()
      )
    if (filteredClients.some((item) => item.new)) {
      filteredClients = filteredClients.sort((a, b) => b.new - a.new)
    }
  }
  $: {
    tariffsMap = {}
    tariffs.forEach((t) => {
      tariffsMap[t.id] = t
      if (t.ext_id) tariffsExtMap[t.ext_id] = t
    })
  }
</script>

<Container>
  <div class="chat-wrapper" uk-grid>
    <!-- <div class="uk-width-auto">
            <h3>Чат</h3>
            <p class="uk-text-meta">Чат с клиентами.</p>
        </div> -->

    {#if isConnect}
      <div>
        <h4>Фильтры</h4>
        <div class="uk-margin">
          <label class="uk-form-label uk-margin-small-bottom"
            >Период сообщений</label
          >
          <select
            bind:value={period}
            on:change={() => onPeriodChange()}
            class="uk-select"
          >
            <option value="1 days">1 день</option>
            <option value="3 days">3 дня</option>
            <option value="5 days">5 дней</option>
            <option value="10 days">10 дней</option>
            <option value="15 days">15 дней</option>
            <option value="all">Все время</option>
          </select>
        </div>
        <DatePicker
          datePickerType="single"
          locale="ru"
          dateFormat="d.m.Y"
          bind:value={date_start}
          on:change={() => fetchClients()}
        >
          <DatePickerInput
            labelText="Дата сообщений от..."
            style="width: 160px;"
          />
        </DatePicker>
        <a on:click={() => clearFilters()}>Сбросить</a>
      </div>
      <div
        class="uk-width-auto uk-overflow-auto"
        style="max-height:93vh !important;"
      >
        <div class="uk-width uk-flex">
          <form class="uk-search uk-search-default">
            <span uk-search-icon />
            <input
              class="uk-search-input"
              type="search"
              placeholder="Поиск..."
              bind:value={search}
            />
          </form>
          {#if loading}
            <div uk-spinner />
          {/if}
        </div>
        <List>
          {#each filteredClients as client, index (client.clients_id)}
            <li
              class="client uk-flex uk-flex-middle uk-box-shadow-small uk-border-rounded uk-position-relative"
              class:active={activeClient &&
                client.clients_id == activeClient.clients_id}
              on:click={getMessages(client)}
              style="border: 1px solid {getTariffColor(client)};"
            >
              {#if client.name}
                <div
                  class="avatar uk-flex uk-flex-middle uk-flex-center"
                  style="background-color: #{intToRGB(hashCode(client.name))};"
                >
                  <span>{client.name.substr(0, 1)}</span>
                </div>
                <div>
                  <div
                    style="font-weight: 400; font-size: 16px; margin-bottom: 8px;"
                  >
                    {client.name}
                  </div>
                  <span class="uk-text-meta">{client.clients_id}</span>
                  {#if client.utm}
                    <p style="width: 120px; font-size: 13px;">
                      {client.utm.full_name}
                    </p>
                  {/if}
                </div>
              {:else}
                <div
                  class="avatar uk-flex uk-flex-middle uk-flex-center"
                  style="background-color: #{intToRGB(hashCode('Без имени'))};"
                >
                  <span>БИ</span>
                </div>
                Без имени
              {/if}
              <a
                on:click|stopPropagation={editName(client)}
                class="uk-margin-small-left edit-icon"
                uk-icon="pencil"
              />
              {#if client.new}
                <div
                  class="new-marker uk-position-small uk-position-top-right"
                />
              {/if}
            </li>
          {/each}
        </List>
      </div>
      <div class="uk-width-expand" style="min-width: 300px">
        <div
          class="uk-overflow-auto messages"
          on:scroll={scrollMessagesEl}
          bind:this={messagesEl}
        >
          {#if getMessagesLoading}
            <div class="uk-width uk-flex uk-flex-center uk-flex-middle">
              <div uk-spinner />
            </div>
          {/if}
          {#each messages as message}
            {#if !message.before_created_at || moment(message.before_created_at).format('YYYY-MM-DD') != moment(message.created_at).format('YYYY-MM-DD')}
              <div
                class="uk-width-auto uk-text-center uk-margin-small-bottom uk-margin-top"
                style="position: sticky;
                            top: 0;
                            z-index: 9;"
              >
                <div
                  class="uk-text-meta"
                  style="background: white;
                                border-radius: 5px;
                                padding: 3px; width: 150px; margin: 0 auto;
                                "
                >
                  {moment(message.created_at).format('LL')}
                </div>
              </div>
            {/if}
            {#if message.users_id !== message.before_users_id}
              <div class="uk-margin-small-bottom uk-margin-top">
                <span
                  class="uk-text-meta"
                  class:manager={message.users_id === $user.id}
                  >{message.manager_name
                    ? message.manager_name + ' (менеджер)'
                    : message.client_name + ' (клиент)'}</span
                >
              </div>
            {/if}
            <div
              class="message uk-flex uk-position-relative"
              class:manager={message.users_id === $user.id}
              on:contextmenu|preventDefault={() => handleEditMessage(message)}
              style="cursor: pointer;"
            >
              {#if message.manager_photo && message.users_id !== $user.id && message.users_id !== message.before_users_id}
                <div
                  class="chat-photo uk-margin-small-right"
                  style="background-image: url({message.manager_photo});"
                />
              {:else if message.users_id !== $user.id && message.users_id}
                <div class="chat-photo uk-margin-small-right" />
              {/if}
              <div class="bubble uk-text-break uk-position-relative">
                <span class="uk-margin-bottom-small"
                  >{@html urlify(message.content)}</span
                >
                <div
                  class="uk-position-absolute uk-position-bottom-right uk-text-meta"
                >
                  {moment(message.created_at).format('HH:mm')}
                </div>
              </div>
              {#if message.manager_photo && message.users_id === $user.id && message.users_id !== message.before_users_id}
                <div
                  class="chat-photo uk-margin-small-left"
                  style="background-image: url({message.manager_photo});"
                />
              {:else if message.users_id === $user.id && message.users_id}
                <div class="chat-photo uk-margin-small-right" />
              {/if}
            </div>
          {/each}
        </div>
        {#if activeClient}
          <Textarea rows="2" bind:value={message} />
          <Button on:click={send}>Отправить сообщение</Button>

          {#if toggleAnsweredFlagLoading}
            <div uk-spinner />
          {:else}
            <Button color="default" size="small" on:click={toggleAnsweredFlag}>
              {activeClient.new
                ? 'Отметить как прочитанное'
                : 'Отметить как непрочитанное'}
            </Button>
          {/if}
        {/if}
      </div>
      {#if activeClient && activeClient.utm}
        <div class="uk-width-expand">
          <div class="uk-box-shadow-small uk-border-rounded uk-padding-small">
            <span class="uk-text-meta">Наименование из биллинга</span>
            <p class="uk-margin-bottom">{activeClient.utm.full_name}</p>
            <span class="uk-text-meta">Фактический адрес</span>
            <p class="uk-margin-bottom">
              {activeClient.utm.actual_address != ''
                ? activeClient.utm.actual_address
                : '-'}
            </p>
            <span class="uk-text-meta">Комментарии</span>
            <p class="uk-margin-bottom">
              {activeClient.utm.comments != ''
                ? activeClient.utm.comments
                : '-'}
            </p>
            <span class="uk-text-meta">Паспорт из биллинга</span>
            <p class="uk-margin-bottom">{activeClient.utm.passport}</p>
            <p>
              Баланс: {activeClient.utm.account
                ? `${activeClient.utm.account.balance}₽`
                : '-'}
            </p>
            <p>
              Кредит: {activeClient.utm.account
                ? `${activeClient.utm.account.credit}₽`
                : '-'}
            </p>
            <p class="uk-margin-bottom">
              Состояние: {@html activeClient.utm.account
                ? activeClient.utm.account.int_status == '1'
                  ? `<span style="color: green">Вкл</span>`
                  : '<span style="color: red">Выкл</span>'
                : '-'}
            </p>
            <span class="uk-text-meta">Услуги</span>
            <div class="uk-margin-bottom">
              {#if activeClient.utm.services}
                {#each activeClient.utm.services as service}
                  <p>{service.service_name}</p>
                {/each}
              {:else}
                <p>-</p>
              {/if}
            </div>
            <span class="uk-text-meta">Номер icq</span>
            <p class="uk-margin-bottom">
              {@html activeClient.utm.icq_number &&
              activeClient.utm.icq_number != ''
                ? activeClient.utm.icq_number
                : '<span style="color: red">Нет</span>'}
            </p>
            <span class="uk-text-meta">IP Группы</span>
            {#if activeClient.utm.ip_groups}
              {#each activeClient.utm.ip_groups as ip_group}
                <p>{ip_group.ip}</p>
              {/each}
            {:else}
              <p>-</p>
            {/if}
            <div class="uk-text-meta uk-margin-top">Группа из биллинга</div>
            {#if activeClient.utm.groups}
              {#each activeClient.utm.groups as group}
                <p>{group.group_name}</p>
              {/each}
            {:else}
              <p>-</p>
            {/if}
          </div>
        </div>
      {/if}
    {:else}
      <div class="uk-margin-large-top uk-flex">
        <p class="uk-margin-small-right">Идет подключение к чату...</p>
        <Spinner />
      </div>
    {/if}
  </div>
</Container>

<style>
  .chat-photo {
    width: 40px !important;
    height: 40px !important;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
  }

  .active {
    background-color: rgba(255, 0, 0, 0.2);
  }

  .message {
    text-align: left;
    margin-bottom: 5px;
  }

  .message .bubble {
    padding: 5px;
    width: 200px;
    background: #f1f1f1;
    border-radius: 5px;
  }

  .manager {
    display: flex;
    justify-content: flex-end;
  }

  .manager .bubble {
    background: rgba(0, 0, 255, 0.1);
  }

  .avatar {
    border-radius: 100%;
    width: 35px;
    height: 35px;
    background-color: rgb(125, 152, 234);
    color: white;
    margin-right: 10px;
  }

  .client {
    cursor: pointer;
    padding: 15px;
    margin-bottom: 5px;
    width: 250px;
  }

  .client.active {
    background-color: aquamarine;
  }

  .client .new-marker {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 100%;
  }

  .client .edit-icon {
    display: none;
  }

  .client:hover .edit-icon {
    display: block;
  }

  .messages {
    height: 65vh;
  }
</style>
