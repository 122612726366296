<script>
  import { api } from 'main/stores.js'
  import ViewTableLayout from 'layouts/ViewTableLayout.svelte'
  import SipTable from 'components/tables/SipTable.svelte'

  let actions = []
</script>

<ViewTableLayout
  title="Sip"
  description="Организация sip аккаунтов в системе."
  component={SipTable}
  endpoint={$api.sip}
  path="/sip"
  {actions}
/>
