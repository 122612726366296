<script>
    import { router } from 'main/stores.js';
    import { msgSuccess } from 'main/modules/Messages.svelte';

    import Tools from 'components/Tools.svelte';
    import Container from 'UI/Container.svelte';

    export let actions;
    export let path;
    export let title;
    export let description;
    export let endpoint;
    export let component;

    let refreshTable;

    const create = () => {
        $router.navigate(`${path}/create`);
    }

    const truncate = () => {
        if (confirm(`Удалить все ${title}?`)) {
            endpoint.delete().then(answer => {
                if(answer && answer.code == 200){
                    msgSuccess(`Все ${title} удалены успешно`);
                    refreshTable();
                }
            });
        }
    }
</script>

<Container>
    <div class="uk-width-large">
        <h3>{title}</h3>
        <p class="uk-text-meta">{description}</p>
    </div>

    <div class="uk-width">
        <Tools size="small" 
            items={actions}
            on:create="{create}"
            on:truncate="{truncate}" 
        />
    </div>

    <hr>

    <svelte:component this={component} bind:refresh={refreshTable} />
</Container>