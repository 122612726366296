<script>
    import { api } from 'main/stores.js';
    import ViewTableLayout from 'layouts/ViewTableLayout.svelte';
    import TariffsTable from 'components/tables/TariffsTable.svelte';

    let actions = [{
        key: 'create',
        name: 'Создать тариф'
    }]
</script>

<ViewTableLayout 
    title="Тарифы"
    description="Организация тарифов в системе."
    component={TariffsTable}
    endpoint={$api.tariffs}
    path="/tariffs"
    {actions}
/>