<script context="module">
    export function checkValid(data) {
        let invalid = {
            photo_url: false,
            name: false,
            last_name: false,
            email: false,
            password: false,
        }

        if (!data.photo_url || data.photo_url == '') invalid.photo_url = true
        if (!data.name || data.name == '') invalid.name = true
        if (!data.last_name || data.last_name == '') invalid.last_name = true
        if (!data.email || data.email == '') invalid.email = true
        if (!data.password || data.password == '') invalid.password = true

        if (data.password != data.password_agree) invalid.password = true
    
        return invalid
    }
</script>

<script>
    import Form from 'UI/Form.svelte';
    import Input from 'UI/Input.svelte';
    import Password from 'UI/Password.svelte';
    import Button from 'UI/Button.svelte';

    export let data = {
        photo_url: '',
        name: '',
        last_name: '',
        email: '',
        password: '',
        password_agree: '', 
    }

    export let invalid = {
        photo_url: false,
        name: false,
        last_name: false,
        email: false,
        password: false,
    }
</script>

<Form>
    <div class="uk-margin-small-top" uk-grid>
        <div class="uk-width">
            <Input label="Фото менеджера (ссылка)" bind:value={data.photo_url} danger={invalid.photo_url} />
            <img class="uk-width-1-2" src={data.photo_url} />
        </div>
        <div class="uk-width-1-2">
            <Input label="Имя" bind:value={data.name} danger={invalid.name} />
        </div>
        <div class="uk-width-1-2">
            <Input label="Email" bind:value={data.email} danger={invalid.email} />
        </div>
        <div class="uk-width">
            <Input label="Фамилия" bind:value={data.last_name} danger={invalid.last_name} />
        </div>
        <div class="uk-width-1-2">
            <Password label="Пароль" bind:value={data.password} danger={invalid.password} />
        </div>
        <div class="uk-width-1-2">
            <Password label="Повторите пароль" bind:value={data.password_agree} danger={invalid.password} />
        </div>
    </div>
</Form>