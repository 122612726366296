<script>
    import { api } from 'main/stores.js';
    import EditFormLayout from 'layouts/EditFormLayout.svelte';
    import NewsForm, { checkValid } from 'components/forms/News.svelte';

    export let data;
</script>

<EditFormLayout 
    title="Редактирование новости {data.name}"
    component={NewsForm}
    endpoint={$api.news(data.id)}
    redirect="\news"
    {checkValid}
    {data}
/>