<script>
    import { api } from 'main/stores.js';
    import ViewTableLayout from 'layouts/ViewTableLayout.svelte';
    import NewsTable from 'components/tables/NewsTable.svelte';

    let actions = [{
        key: 'create',
        name: 'Создать новость'
    }, {
        key: 'truncate',
        name: 'Очистисть таблицу',
        color: 'danger'
    }]
</script>

<ViewTableLayout 
    title="Новости"
    description="Организация новостей в системе. Все новости будут отображаться в мобильном приложении Orbita telecom."
    component={NewsTable}
    endpoint={$api.news}
    path="/news"
    {actions}
/>