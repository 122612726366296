<script>
  export let value = ''
  export let size = 'default'
  export let status = 'default'
  export let placeholder = ''
  export let label = ''
  export let rows = 5
  export let danger = false
  let textarea
  let isShiftKeydown = false

  function onKeydown(e) {
    console.log({ e })
    console.log('textarea.selectionStart', textarea.selectionStart)
    if (e.key == 'ArrowLeft') {
      if (textarea.selectionStart > 0) {
        textarea.selectionStart = textarea.selectionStart - 1
        textarea.selectionEnd = textarea.selectionStart
      }
    }
    if (e.key == 'ArrowRight') {
      textarea.selectionStart = textarea.selectionStart + 1
      textarea.selectionEnd = textarea.selectionStart
    }
    if (e.key == 'ArrowUp') {
      if (value.includes('\n')) {
        let q = 0
        for (let i = textarea.selectionStart - 1; i > 0; i--) {
          if (value[i] != '\n') q++
          else break
        }
        let beforeLineLength = 1
        for (let i = textarea.selectionStart - q - 1; i > 1; i--) {
          if (value[i - 1] != '\n') beforeLineLength++
          else break
        }
        if (beforeLineLength < q) {
          textarea.selectionStart = textarea.selectionStart - q - 1
          textarea.selectionEnd = textarea.selectionStart
        } else {
          textarea.selectionStart =
            textarea.selectionStart - q - 1 + (q - beforeLineLength)
          textarea.selectionEnd = textarea.selectionStart
        }
      }
    }
    if (e.key == 'ArrowDown') {
      if (value.includes('\n')) {
        let q = 1
        for (let i = textarea.selectionStart - 1; i > 0; i--) {
          if (value[i] != '\n') q++
          else break
        }
        console.log({ q })
        let afterSelectedLength = 1
        for (let i = textarea.selectionStart; i < value.length - 1; i++) {
          if (value[i] != '\n') afterSelectedLength++
          else break
        }
        console.log({ afterSelectedLength })
        textarea.selectionStart =
          textarea.selectionStart + afterSelectedLength + q
        textarea.selectionEnd = textarea.selectionStart
      }
    }
    if (e.key == 'Shift') {
      isShiftKeydown = true
    }
    if (e.key == 'Enter' && isShiftKeydown) {
      value += '\n'
      rows = Number(rows) + 1
    }
  }
  function onKeyup(e) {
    if (e.key == 'Shift') {
      isShiftKeydown = false
    }
  }
</script>

<div class="uk-margin">
  <label class="uk-form-label">{label}</label>
  <div class="uk-form-controls">
    <textarea
      bind:this={textarea}
      class="uk-textarea"
      class:uk-form-small={size == 'small'}
      class:uk-form-large={size == 'large'}
      class:uk-form-success={status == 'success'}
      class:uk-form-danger={danger}
      {rows}
      disabled={status == 'disabled' ? 'disabled' : ''}
      {placeholder}
      bind:value
      on:keydown={(e) => onKeydown(e)}
      on:keyup={(e) => onKeyup(e)}
      on:change
    />
  </div>
</div>
