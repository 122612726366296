<script>
    import { api } from 'main/stores.js';
    import EditFormLayout from 'layouts/EditFormLayout.svelte';
    import TariffsForm, { checkValid } from 'components/forms/Tariffs.svelte';
</script>

<EditFormLayout 
    title="Создание тарифа"
    component={TariffsForm}
    endpoint={$api.tariffs}
    redirect="/tariffs"
    {checkValid}
/>