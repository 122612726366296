<script>
  import { onMount } from 'svelte'
  import Launch16 from 'carbon-icons-svelte/lib/Launch16'
  import { router, api } from 'main/stores.js'
  import { msgSuccess } from 'main/modules/Messages.svelte'
  import Table from 'UI/Table.svelte'
  import Row from './ClientsTableRow.svelte'
  import {
    Modal,
    DataTable,
    DataTableSkeleton,
    Pagination,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
    ToolbarMenu,
    ToolbarMenuItem,
    Button,
    TextInput,
  } from 'carbon-components-svelte'
  import {
    searchInStrings,
    searchInString,
  } from '../../../helpers/strings.helper'
  import SipForm from '../forms/Sip.svelte'

  let headers = [
    { key: 'id', value: 'ID' },
    { key: 'sip_login', value: 'SIP логин' },
    { key: 'apartment', value: 'Квартира' },
    { key: 'actions', value: '' },
  ]
  let headersMap = {}
  headers.forEach((h) => (headersMap[h.key] = h))

  let data = []
  let activeSip = {}
  let createSipFormData = {}
  let filteredData = []
  let rows = []
  let cameras = []
  let tariffs = []
  let openEditModal = false
  let openCreateModal = false
  let totalItems = 0
  let pageSizes = [10, 15, 20]
  let page = 1
  let pageSize = 10
  let search = ''
  let loading = true

  const openSipEditModal = (sip) => {
    console.log({ sip })
    openEditModal = true
    activeSip = sip
  }

  export const refresh = async () => {
    loading = true
    const answer = await $api.sip.get({ limit: 999 })
    if (answer && answer.code == 200) {
      data = answer.data.sip
    }
    loading = false
  }

  const createSip = async () => {
    const answer = await $api.sip.post({
      ...createSipFormData,
      rtsp_home: createSipFormData.rtsp_home.map((item) => item.url).join(','),
    })
    if (answer && answer.code == 200) {
      msgSuccess('Успешно сохранено')
      openCreateModal = false
      refresh()
    }
  }

  const saveActiveSip = async () => {
    if (!activeSip) return
    const answer = await $api.sip(activeSip.id).post({
      ...activeSip,
      rtsp_home: activeSip.rtsp_home.map((item) => item.url).join(','),
    })
    if (answer && answer.code == 200) {
      msgSuccess('Успешно сохранено')
      openEditModal = false
      refresh()
    }
  }

  const deleteSip = async (sip) => {
    if (!sip) return
    const answer = await $api.sip(sip.id).delete()
    if (answer && answer.code == 200) {
      msgSuccess('Успешно удалено')
      refresh()
    }
  }

  onMount(async () => {
    await refresh()
    loading = false
  })

  $: rows = filteredData.filter(
    (_, i) => i >= pageSize * (page - 1) && i < pageSize * page
  )
  $: totalItems = filteredData.length
  $: if (search && search != '') {
    filteredData = data.filter((item) =>
      searchInStrings([item.sip_login, item.apartment], search)
    )
  } else {
    filteredData = data
  }
</script>

<Modal
  modalHeading="Редактирование SIP {activeSip.sip_login}"
  primaryButtonText="Сохранить"
  secondaryButtonText="Отмена"
  on:click:button--secondary={() => (openEditModal = false)}
  on:submit={saveActiveSip}
  bind:open={openEditModal}
>
  <SipForm bind:data={activeSip} />
</Modal>

<Modal
  modalHeading="Создание SIP аккаунта"
  primaryButtonText="Сохранить"
  secondaryButtonText="Отмена"
  on:click:button--secondary={() => (openCreateModal = false)}
  on:submit={createSip}
  bind:open={openCreateModal}
>
  <SipForm bind:data={createSipFormData} />
</Modal>

{#if loading}
  <DataTableSkeleton showHeader={false} />
{:else}
  <DataTable {headers} {rows}>
    <Toolbar>
      <ToolbarContent>
        <ToolbarSearch bind:value={search} />
        <Button on:click={() => (openCreateModal = true)}>
          Создать SIP аккаунт
        </Button>
      </ToolbarContent>
    </Toolbar>
    <span slot="cell" let:row let:cell>
      {#if cell.key === 'actions'}
        <a
          on:click={() => openSipEditModal(row)}
          class="uk-icon-button uk-margin-small-right"
          uk-tooltip="Просмотр"
        >
          <span uk-icon="pencil" />
        </a>
        <a
          on:click={() => deleteSip(row)}
          class="uk-icon-button uk-margin-small-right"
          uk-tooltip="Удалить"
        >
          <span uk-icon="trash" />
        </a>
      {:else}
        {cell.value}
      {/if}
    </span>
  </DataTable>
  <Pagination {totalItems} {pageSizes} bind:page bind:pageSize />
{/if}
