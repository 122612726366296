<script>
    import { api } from 'main/stores.js';
    import ViewTableLayout from 'layouts/ViewTableLayout.svelte';
    import CamerasTable from 'components/tables/CamerasTable.svelte';

    let actions = [{
        key: 'create',
        name: 'Создать объект камеры'
    }, {
        key: 'truncate',
        name: 'Очистисть таблицу',
        color: 'danger'
    }]
</script>

<ViewTableLayout 
    title="Объекты камер"
    description="Ссылки на камеры, которые будут видеть пользователи в мобильном приложении Orbita telecom."
    component={CamerasTable}
    endpoint={$api.cameras}
    path="/cameras"
    {actions}
/>